.psychodynamic {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .psychodynamic:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: .02;
  }

  .psychodynamic-content {
    padding: 1rem;
  }

  .psychodynamic-flex {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .psychodynamic-right {
    width: 50%;
    margin: auto;
  }

  .psychodynamic-right h1 {
    padding: .5rem 0;
    text-align: center;
  } 
  .psychodynamic-right p {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: rgb(65, 66, 66);
    text-align: justify;  
  }

  .psychodynamic-left {
    width: 500px;
  }

  .psychodynamic-left img {
    width: 100%;
    border-radius: 1rem;
  }

  .source p{
    padding: 1rem 0;
    font-size: 10px;
  }

  @media  screen and (max-width:940px) {


    .psychodynamic-right {
        width: 100%;
      }
      
    .psychodynamic-left {
      width: 100%;
    }

      .psychodynamic-flex {
        flex-wrap: wrap-reverse;
      }

  }