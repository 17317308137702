@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.about {
    width: 100%;
    height: 80vh;
    position: relative;
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: #24232377;
}
  
  .about:before {
    content: '';
    position: absolute;
    background: url('../../assets/menu-bg-about-min.webp') no-repeat center top/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.about .about-content {
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
}

.about h1 {
    font-size: 4rem;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    font-family: 'Kuta', serif;
    line-height: normal;
    text-align: center;
}





/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.about h2 {
    color: white;
    font-size: 1.5rem;
}

.about h1 {
    font-size: 3rem;
}
}