
.php {
    width: 100%;
    height: 70vh;
    background: rgba(0, 0, 0, 0.39);
    position: relative;
  }
  
  .php:before {
    content: '';
    position: absolute;
    background: url('../../assets/programs_bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.php-content {
    position: absolute;
    bottom: 10%;
    padding: 1rem;
    width: 100%;
}

.php h1 {
    font-size: 4rem;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    font-family: 'Kuta', serif;
    line-height: normal;
    text-align: center;
}

.php p {
    color: rgba(255, 255, 255, 0.829);
    font-size: .8rem;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

    
    .php h1 {
        font-size: 2rem;
    }

    
    }