@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.aboutSection {
    width: 100%;
    height: auto;
    background: rgba(0, 0, 0, 0);
    position: relative;
  }
  
  .aboutSection:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.06;
  }

.aboutContent {
    padding: 3rem 1rem;
}

.aboutSectionFlex {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.aboutSectionImage {
    width:100%;
    margin: auto;
}

.aboutSectionImage img {
    width: 100%;
    border-radius: 1rem;
}

.aboutSectionText {
    width: 100%;
    margin: auto;
}


.aboutSectionText h1 {
    text-transform: uppercase;
    color: #fd9b2f;
    font-family: 'Roboto', serif;
    font-size: 1rem;
    letter-spacing: 5px; 
}

.aboutSectionText p {
    font-size: 15px;
    text-align: left;
}

.aboutSectionText ul {
    list-style: disc;
    padding: 0 1rem;
}




@media screen and (max-width:940px) {

    .aboutSectionFlex {
        flex-wrap: wrap-reverse;
    }

}
