@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@400..700&family=Great+Vibes&display=swap');


.TherapyMH {
    width: 100%;
    height: auto;
    background: rgb(241, 241, 241);
    position: relative;
  }
  
  .TherapyMH:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.06;
  }


.TherapyMH .content {
    top: 10%;
    padding: 5rem 0;
    text-align: center;
}



/* HEADER */

.TherapyMH .TherapyMH-header {
    width: 100%;
    text-align: center;
    padding: 1rem 0;
}

.TherapyMH .left-content {
    top: 15%;
}

.TherapyMH .TherapyMH-header h1{
    font-family: "Roboto", sans-serif;   
    color: rgb(26, 25, 25); 
    font-size: 1.5rem;
    letter-spacing: 5px; 
    text-align: center;
    font-weight: normal;
}

.TherapyMH .TherapyMH-header h2 {
    color: #3c4c24;    
    font-family: "Great Vibes", cursive;
    font-size: 1.8rem;
    letter-spacing: 3px;
    text-align: center;
}


/* CARD */

.card {
    background-color: #3c4c24;
}

.mh-flex {
    margin: auto;
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
}

.mh-flex .box-icon {
    font-size: 3rem;
    color: #fd9b2f;
    padding: 0;
}

.cardImage {
    overflow: hidden; /* Ensure the image doesn't overflow its container */
    z-index: 99999;
    border: 1px solid rgba(255, 255, 255, 0.295);

}

.cardImage img {
    width: 100%;
    transition: transform 0.6s ease-in-out; /* Smooth transition for transform */
}

.cardImage img:hover {
    transform: scale(1.2); /* Scale the image up on hover */
}

.mh-flex h1 {
    color: rgb(240, 231, 231);
    font-size: 1rem;
    margin-bottom: 0;
    padding: .5rem 0;
}

.mh-flex p {
    color: rgb(240, 231, 231);
    padding: 0 .3rem;
    text-align: center;
    font-size: 14px;
}

.mh-flex .card {
    width: 35%;
}




/* button */

.TherapyMH-btn {
    width: 100%;
    text-align: center;
}

.TherapyMH-btn button {
    background-color: #3c4c24c4;
    color: white;
    font-size: 14px;
}



@media screen and (max-width:940px) {


    .mh-flex{
        flex-wrap: wrap;
    }

    .mh-flex .card {
        width: 90%;
        -webkit-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
        -moz-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
        box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
    }

    .TherapyMH .TherapyMH-header p {
        font-size: 2rem;
        letter-spacing: 0;
    }


}


@media screen and (max-width:600px) {
    .TherapyMH .TherapyMH-header h1{
        font-size: 1.2rem;
        letter-spacing: 3px; 

    }
    
    .TherapyMH .TherapyMH-header h2 {
        font-size: 1rem;
        letter-spacing: 3px;
        text-align: center;
    }

    .TherapyMH-btn button {
        background-color: #3c4c24c4;
        color: white;
        font-size: 12px;
    }
    
}

