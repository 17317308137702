@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');



.TherapySA {
    width: 100%;
    height: auto;
    position: relative;
    background: #1C3B48;
}
  

.TherapySA .content {
    display: flex;
    gap: 1rem;
    padding: 5rem 1rem;
}

.box-icon {
    font-size: 6rem;
    color: rgb(230, 224, 215);
    padding: 1rem 0;
}


/* LEFT */

.TherapySA .TherapySA-left {
    width: 50%;
    margin: auto;
}


.TherapySA .TherapySA-left h1{
    color: rgb(243, 237, 237);
    font-size: 2rem;
    padding: 2rem 0;
}

.TherapySA .TherapySA-left p {
    color: rgb(201, 197, 197);
    font-size: 15px;
    text-align: left;
}


/* RIGHT */

.box-icon {
    width: 100%;
}

 .TherapySA-right {
    width: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
}

.TherapySA-right .box {
    height: auto;
    width: 45%;
    background-color: rgba(0, 0, 0, 0.116);
    padding: .5rem ;
}


/* box 1 */

.box1 .box-content1 h1 {
    color: rgb(201, 197, 197);
    padding: 1rem 0;
    font-size: 1.2rem;
    text-align: center;
    text-align: center;
    width: 100%;
    padding: 1rem;

}

.box1 .box-content1 p {
    color: rgb(238, 230, 230);
    text-align: center;
    padding: 0 1rem;
    text-align: center;
}

/* box 2 */

.box2 .box-content2 h1 {
    color: rgb(201, 197, 197);
    padding: 1rem 0;
    font-size: 1.2rem;
    text-align: center;

}

.box2 .box-content2 p {
    color: rgb(238, 230, 230);
    text-align: center;
    padding: 0 1rem;
    text-align: center;
}

/* box 3 */

.box3 .box-content3 h1 {
    color: rgb(201, 197, 197);
    padding: 1rem 0;
    font-size: 1.2rem;
    text-align: center;

}

.box3 .box-content3 p {
    color: rgb(238, 230, 230);
    text-align: center;
    padding: 0 1rem;
    text-align: center;
}

/* box 4 */

.box4 .box-content4 h1 {
    color: rgb(201, 197, 197);
    padding: 1rem 0;
    font-size: 1.2rem;
    text-align: center;
}

.box4 .box-content4 p {
    color: rgb(238, 230, 230);
    text-align: center;
    padding: 0 1rem;
    text-align: center;
}


/* button */

.TherapySA-btn {
    width: 100%;
    text-align: left;
}


.TherapySA-btn button {
    background-color: rgba(255, 255, 255, 0.397);
    color: white;
}

.TherapySA-btn {
    text-align: center;
    padding: 1rem 0;
}


/*  */

@media screen and (max-width:940px) {
    .TherapySA .TherapySA-left {
        width: 100%;
    }

    .hide {
        display: none;
    }

    .hide-btn {
        display: block;
        padding: 2rem 0;
    }


    .TherapySA .content {
        flex-wrap: wrap;
        text-align: center;
        padding: 1rem;
    }

    .TherapySA .content p {
        text-align: center;
        padding: 0 1rem;
    }

}


@media screen and (max-width:940px) {


    .TherapySA-right  .box{
        width: 90vw;
    }
    
    .TherapySA .TherapySA-left h1{
        font-size: 2rem;
    }

    .TherapySA .TherapySA-left {
        width: 90vw;
    }

    .TherapySA .TherapySA-right {
        width: 90%;
    }

    .TherapySA-btn {
        text-align: center;
    }
    
}

