
.insuranceSection {
    width: 100%;
    height: auto;
    background: rgba(0, 0, 0, 0);
    position: relative;
  }
  
  .insuranceSection:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.06;
  }
  

.insuranceFlex {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 3rem 0;

}

.insuranceLogos {
    width: 100%;
    margin: auto;
}

.insuranceSectionHeader {
    padding: 1rem 0;
    text-align: center;
}

.insuranceSectionHeader h1 {
    color: rgb(37, 35, 35);
}

.insuranceSectionHeader p {
    color: rgb(37, 35, 35);
}

.insuranceThumbnails {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.insurance-thumbnail {
    width: 100%;
}

.insuranceFlexImg {
    width: 50%;
}


.insurance-thumbnail img{
    width: 100%;
    opacity: 0.6;
}


.insurance-thumbnail img:hover{
    -webkit-filter: sepia(100%) saturate(1000%) hue-rotate(90deg);
    filter: sepia(100%) saturate(1000%) hue-rotate(90deg);
}


.insuranceFlexImg img{
    width: 100%;
    display: block;
}

@media  screen and (max-width: 940px) {
    .insuranceThumbnails {
        flex-wrap: wrap;
    }


    .insurance-thumbnail {
        width: 25%;
    }
    
    .insurance-thumbnail img{
        width: 100%;
        opacity: 0.6;
    }

    .insuranceFlex {
        flex-wrap: wrap;
    }

    .insuranceFlexImg {
        width: 80%;
    }



}

