.iop {
    width: 100%;
    height: 70vh;
    background: rgba(0, 0, 0, 0.507);
    position: relative;
  }
  
  .iop:before {
    content: '';
    position: absolute;
    background: url('../../assets/programs_bg.webp') no-repeat center top/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.iop-content {
    width: 100%;
    position: absolute;
    bottom: 10%;
    padding: 1rem;
}

.iop-content h1 {
    font-size: 4rem;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    font-family: 'Kuta', serif;
    line-height: normal;
    text-align: center;
}

.iop p {
    color: rgba(255, 255, 255, 0.89);
    font-size: .8rem;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {


    
        .iop h1 {
            font-size: 2rem;
        }
    
    
    }

