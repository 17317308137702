@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.addiction {
  width: 100%;
  min-height: 60vh; /* Ensures full viewport height */
  background: rgba(0, 0, 0, 0.39);
  position: relative;
  display: flex;
  justify-content: center;
}

.addiction:before {
  content: '';
  position: absolute;
  background: url('../../assets/clinic_bg.webp') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.addictionFlex {
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: flex-end; /* Aligns items to the bottom */
  padding-top: 3rem;
}

.addictionText {
  width: 50%;
  padding: 1rem;
  padding-bottom: 10rem; /* Adds space above the bottom */
  box-sizing: border-box;
}

.addictionText h1 {
  font-size: 1.3rem;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  padding-bottom: .5rem;
}

.addictionText p {
  text-align: justify;
  color: white;
}
.addictionImage {
  flex: 0 0 auto; /* Prevents the image container from shrinking or growing */
  width: auto;
}

.addictionImage img {
  width: 500px; /* Fixed width */
  height: auto; /* Maintain aspect ratio */
}

.addictionBtn {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  text-align: center;
}

/* MEDIA QUERIES */

@media screen and (max-width: 940px) {
  .addictionFlex {
    flex-wrap: wrap-reverse;
    gap: 0;
  }

  .addictionText, .addictionImage {
    width: 100%;
    margin: auto;
    text-align: center;
  }

  .addiction-btn {
    text-align: center;
  }

  .addictionText {
    padding-bottom: 1rem; /* Adjust padding for smaller screens */
  }


}
