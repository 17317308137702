@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');



.addictionSection {
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0);
  position: relative;
}

.addictionSection:before {
  content: '';
  position: absolute;
  background: url('../../assets/section-bg.webp') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.07;
}

.addictionSectionHeader ul {
  list-style-type: disc;
  padding: 0 1rem;
}

.addictionSectionHeader h1 {
  font-size: 1.2rem;
  color: rgb(105, 102, 102);
}

.addictionSectionHeader p {
  text-align: left;
}

.addictionSectionContent {
  padding: 3rem 0rem;
}

.addictionSectionFlex {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 2rem 0;
}

.addictionSectionText {
  width: 100%;
  margin: auto;
}

.addictionSectionText h1 {
  font-size: 1.2rem;
  color: rgb(105, 102, 102);
}

.addictionSectionText ul {
  list-style: disc;
  padding: 0 1rem;
}

.addictionSectionText p {
  text-align: justify;
}

.addictionSectionImage {
  width: 100%;
  margin: auto;
  -webkit-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
  -moz-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
  box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
}

.addictionSectionImage img {
  width: 100%;
  border-radius: 15px;
}

.addictionSectionFooter ul {
  list-style-type: disc;
  padding: 0 1rem;
}

.addictionSectionFooter h1 {
  font-size: 1.2rem;
  color: rgb(105, 102, 102);
}

.addictionSectionFooter p {
  text-align: left;
}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

  .addictionSectionFlex {
    flex-wrap: wrap-reverse;
  }
  
  .addictionSectionText {
    width: 100%;
    margin: auto;
  }
  
  .addictionSectionImage {
    width: 100%;
    margin: auto;
  }

  
  }



