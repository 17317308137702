@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Poppins:wght@300&display=swap');


.mission-home {
    width: 100%;
    height: auto;
    position: relative;
    background: rgba(255, 255, 255, 0.438);
  }
  
  .mission-home:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.1;
  }

  .missionFlex {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }

  .missionFlexImage {
    width: 100%;
  }

  .missionFlexImage img {
    width: 100%;
  }

  .missionFlexText {
    width: 100%;
    margin: auto;
  }



@media screen and (max-width:940px) {

  .missionFlex {
    flex-wrap: wrap;
  }

  .missionFlexText {
    text-align: center;
  }


    }
