.treatmentPlan {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .treatmentPlan:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: .02;
  }

  .treatmentPlan-content {
    padding: 1rem;
  }
 

  .treatment-header p {
    text-align: center;
  }

  .treatment-header h1{
    text-align: center;
    padding: .5rem 0;
  }

  .treatmentPlan-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }

  .treatmentPlan-right {
    width: 50%;
    margin: auto;
  }

  .treatmentPlan-right h1 {
    padding: .5rem 0;
    text-align: center;
  } 
  .treatmentPlan-right p {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: rgb(65, 66, 66);
    text-align: justify;  
  }

  .treatmentPlan-left {
    width: 420px;
  }

  .treatmentPlan-left img {
    width: 100%;
    border-radius: 1rem;
  }

  .source p{
    padding: 1rem 0;
    font-size: 10px;
  }

  @media  screen and (max-width:940px) {
    .treatmentPlan-right {
        width: 100%;
      }

      .treatmentPlan-flex {
        flex-wrap: wrap-reverse;
      }

  }