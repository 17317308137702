.sectionBg {
    width: 100%;
    height: auto;
    background: rgba(0, 0, 0, 0);
    position: relative;
  }
  
  .sectionBg:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.06;
  }


.section5 {
    padding: 5rem 0;
}

.section5-content {
    width: 80%;
    margin: auto;
    padding: 0 2rem;
    text-align: center;
}

.section5-content h1 {
    text-transform: uppercase;
    color: #3c4c24;
    font-size: 2rem;
    letter-spacing: 2px;
    font-family: 'Kuta', serif;
    line-height: 2rem;
}

.section5-content p {
    color: rgb(68, 62, 62);
}

  .section5Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #3c4c24; /* Change color as needed */
    font-size: 50px;
    color: rgb(248, 241, 241);
    margin: auto;
  }

.section5FlexItem {
    width: 30%;
    text-align: center;
}

.section5FlexItem p {
    color: rgb(68, 62, 62);
}

  .section5Icons h1 {
    font-size: 1rem;
    color: rgb(68, 62, 62);
    text-transform: uppercase;
    padding: 1rem 0;
  }

  .section5Icons h2 {
    font-size: 5rem;
    padding: 1rem 0;
    color: #3c4c24;    
  }
  
  .section5Icons {
    display: flex;
    gap: 1rem;
    justify-content: center;
    padding-top: 3rem;
    position: relative;
  }

  .arrow {
    width: max-content;
}

.arrowDown {
    display: none;
}


.section5fFooter p {
    text-align: center;
    color: rgb(68, 62, 62);
}


.hide {
    display: none;
}

.section5 button {
    background-color: #3c4c24c4;
    color: white;
}

@media  screen and (max-width: 940px) {
    .section5-content h2 {
        font-size: 2rem;
    }

    .arrow {
        width: max-content;
        display: none;
    }
    
    .arrowDown {
        display: block;
    }

    .section5FlexItem {
        width: 80%;
    }
    

    .section5Icons {
        flex-wrap: wrap;
      }

      .show {
        display: none;
    }

    
    .hide {
        display: block;
    }
      

}

@media  screen and (max-width: 600px) {
    .section5-content h1 {
        font-size: 1.3rem;
        font-family: 'Roboto', serif;
        line-height: normal;
    }
}