
.family-therapy {
    width: 100%;
    height: 70vh;
    background: rgba(0, 0, 0, 0.39);
    position: relative;
  }
  
  .family-therapy:before {
    content: '';
    position: absolute;
    background: url('../../assets/programs_bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }


.family-therapy {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.family-therapy .family-therapy-content {
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
}


.family-therapy h1 {
    font-size: 4rem;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    font-family: 'Kuta', serif;
    line-height: normal;
    text-align: center;
}


/* MEDIA QUERIES */

@media screen and (max-width:940px) {

    .family-therapy .family-therapy-content h1 {
        font-size: 2rem;
    }
    
    }