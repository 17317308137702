@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap');


#cards {
  width: 100%;
  height: auto;
  background: rgb(241, 241, 241);
  position: relative;
  padding: 2rem 0;
}

#cards:before {
  content: '';
  position: absolute;
  /* background: url('../../assets/circle_bg.webp') no-repeat center center/cover; */
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0.08;
}

.main-cards-header {
  padding: 1rem;
}

.main-cards-header p {
  font-size: 15px;
  padding: 1rem;
  color: rgb(62, 65, 65);
  font-weight: normal;
  text-align: center;
}

.main-cards-header h2 {
  font-size: 1.2rem;
  text-align: center;
  color: rgb(62, 65, 65);
  letter-spacing: 1px;
  font-family: 'Roboto', serif;
}

.main-cards-header span {
  font-family: "Great Vibes", cursive;  
  font-size: 1.5rem;
  color: rgb(207, 95, 4);
}

.main-cards-header h1 {
  text-align: center;
  font-family: 'Roboto', serif;
  font-size: 1.5rem;
  color: rgb(62, 65, 65);
  letter-spacing: 5px;
}


/* CARDS */

.cards-flex {
  display: flex;
  justify-content: center;
  gap: 1rem;
  
}

.services-card {
  width: 25%;
  z-index: 222;
}

.services-card img {
  width: 100%;
  border-radius: 5px;
}

.services-card h1 {
  font-size: 1rem;
  color: rgb(62, 65, 65);
  text-align: center;
  padding: 1rem;
}

.services-card p {
  font-size: 14px;
  color: rgb(62, 65, 65);
  text-align: center;
  font-family: 'Roboto', serif;

}

.services-card-btn {
  text-align: center;
}


.services-card-btn button {
  background-color: #3c4c24;
  color: rgb(62, 65, 65);
  color: white;
  transition-duration: 0.5s;
}

.services-card-btn button:hover {
  background-color: #3c4c24b2;
  color: white;
}



@media screen and (max-width:940px) {
  .cards-flex {
    flex-wrap: wrap;
  }
  
  .services-card {
    width: 40%;
  }
}


@media screen and (max-width:700px) {
  .cards-flex {
    flex-wrap: wrap;
  }
  
  .services-card {
    width: 80%;
  }
}







