@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.substanceAbuseMenu {
    padding: 5rem 0;
}

.substanceAbuseMenuHeader {
    padding: 1rem;
    text-align: center;
}

.substanceAbuseMenuHeader h1 {
  color: #1C3B48;
font-size: 1.3rem;
}


.substanceAbuseMenuFlex
{
  width:100%;
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.substanceAbuseMenuFlexItem {
    width: 30%;
    background-color: #1C3B48;
    border-radius: 10px;
}

.substanceAbuseMenuFlexItem img {
    width: 100%;
    border-radius: 10px;
}


.menu2-card-caption {
  padding: 1rem;
  text-align: center;
}


.menu2-card-caption p {
  font-size: 14px;
  text-align: center;
  color: white;
}

.menu2-card-caption h1 {
  color: white;
  text-transform: uppercase;
  font-size: 1rem;
}


@media (max-width: 940px) {
  .mh-card-wrap {
    width: 100%;
  }

}


@media (max-width: 700px) {
.substanceAbuseMenuFlexItem {
    width: 40%;
}

.substanceAbuseMenuHeader h1 {
    font-size: 1rem;
}

.card-btn button {
  padding: .4rem .8rem;
}

}
